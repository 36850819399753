@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply text-white bg-accent-primary no-underline inline-block px-7 py-2 mx-0 my-2 cursor-pointer rounded-full;
  }
  .btn-small {
    @apply text-neutral-500 hover:text-neutral-600 dark:text-neutral-300 dark:hover:text-neutral-400 text-xs bg-neutral-300 dark:bg-neutral-700 no-underline inline-block px-4 py-1 mx-0 my-1 cursor-pointer rounded-full border-2 transition-all border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 hover:dark:bg-neutral-800 hover:border-2;
  }
  .btn-not-allowed[disabled] {
    @apply opacity-50;
  }
  .btn[disabled],
  .btn-small[disabled] {
    @apply opacity-50;
  }
  .input {
    @apply inline-block px-3 py-2 rounded-md mx-0 my-2 mb-4 dark:bg-neutral-700 dark:text-neutral-200 w-full;
  }

  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply bg-white dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-neutral-100 dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-600;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-800;
  }
  /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  } */

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600 dark:text-neutral-200;
  }
}
